import { Button, Col, Container, Row, Form, Spinner, Dropdown } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { Trans } from "react-i18next";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { faSquareCheck, faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { goTo } from "../utilities/utils";
import { saveRouteDetails } from "../redux/actions/routeActions";
import { useDispatch } from "react-redux";
import { graphql } from "gatsby";
import { RootState } from "../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { constants } from "../redux/constants";
import Modal from "../components/Modal";
import { api } from "../utilities/api";
import CanadaPostAutoCompleteAddress from "../components/CanadaPostAutoCompleteAddress";
import { MiniPlan } from "../types/MiniPlan";

export default function internalTransfer() {
  const dispatch = useDispatch();
  const shopDetails = useSelector((state: RootState) => state.shop);
  const isUSA = shopDetails.country === constants.USA;
  const [pageWidth, setPageWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(true);
  const [modalError, setModalError] = useState("");
  const [error, setError] = useState("");
  const [existsPendingPortin, setExistsPendingPortin] = useState(false);
  const isShowPlans = !existsPendingPortin && !loading;
  const [uid, setUID] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // additional address params
  const [address, setAddress] = useState("");
  const [autocompleteFinished, setAutocompleteFinished] = useState(false);
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [isCurrentlyESIM, setIsCurrentlyESIM] = useState(false);
  const [acknowledgementChecked, setAcknowledgementChecked] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const parsedMonth = () => {
    let parsedMonth = "";
    let monthActual = month + 2 > 12 ? 1 : month + 2;
    parsedMonth = monthActual.toString();
    if (parsedMonth.length === 1) {
      parsedMonth = "0" + parsedMonth;
    }
    return parsedMonth;
  }

  const DEFAUlT_SIM_OPTION = "Choose an Option";
  const DEFAULT_PLAN_OPTION = {
    PlanCode: "",
    PlanId: 0,
    PlanPrice: "",
    PlanCapacity: ""
  } as MiniPlan;

  const [selectedPlan, setSelectedPlan] = useState(DEFAULT_PLAN_OPTION);
  const [selectedSimCardType, setSelectedSimCardType] = useState(DEFAUlT_SIM_OPTION);
  const [plans, setPlans] = useState([]);
  const physicalSimSelected = selectedSimCardType === "Physical SIM";
  const esimSelected = selectedSimCardType === "ESIM";

  const simCardTypes = [
    { name: "Physical SIM", value: "Physical SIM" },
    { name: "eSIM", value: "ESIM" }
  ];

  const onClickOK = () => {
    if (existsPendingPortin || isSuccess) {
      goTo("/");
    } else if (!existsPendingPortin) {
      setShowModal(false);
    }
  }

  const onChangeSelectedPlan = (plan: MiniPlan) => {
    if (plan.PlanPrice === DEFAULT_PLAN_OPTION.PlanPrice) {
      return;
    }
    setSelectedPlan(plan);
  }

  const onChangeSelectedSimType = (type: string) => {
    if (type === DEFAUlT_SIM_OPTION) {
      return;
    }
    setSelectedSimCardType(type);
  }

  const onClickRequestThePlan = () => {

    // address conds for physical sim selected

    if (physicalSimSelected) {

      if (!address) {
        setError("Please enter your address.");
        return;
      }

      if (!streetAddress) {
        setError("Please enter your street address.");
        return;
      }

      if (!city) {
        setError("Please enter your city.");
        return;
      }

      if (!province) {
        setError("Please enter your province.");
        return;
      }

      if (!postalCode) {
        setError("Please enter your postal code.");
        return;
      }
    }


    if (selectedPlan.PlanPrice === DEFAULT_PLAN_OPTION.PlanPrice || selectedPlan.PlanId === 0) {
      setError("Please select a plan.");
      return;
    }

    if (selectedSimCardType === "Choose an Option") {
      setError("Please choose a SIM card type.");
      return;
    }

    if (!acknowledgementChecked) {
      setError("Please acknowledge that the plan change will be effective next billing cycle.");
      return;
    }

    setError("");

    let portInRequest = {
      PlanId: selectedPlan.PlanId,
      PlanName: selectedPlan.PlanCode,
      SIMType: selectedSimCardType,
      EffectDate: `${year}-${parsedMonth()}-02T00:00:00Z`,
      Address: address,
      CarrierCode: "T",
      PlanType: constants.POSTPAID_PLAN,
      Country: "Canada",
      Partner: "",
      StreetAddress: streetAddress,
      City: city,
      Province: province,
      PostalCode: postalCode,
    }

    setLoadingNext(true);
    api.submitPortInRequest(uid, portInRequest)
      .then(res => {
        console.log(res);
        if (res.data === "success") {
          setShowModal(true);
          setIsSuccess(true);
          setLoadingNext(false);
        } else {
          setLoadingNext(false);
          setError("Something went wrong with processing your request. Please contact our customer support.");
        }
      })
      .catch(err => {
        setLoadingNext(false);
        console.error(err);
        setError("Something went wrong with processing your request. Please contact our customer support.");
      })
  }

  const getServiceAddressAutoComplete = (full_address) => {
    const longCountryName = full_address[5];
    const fullAddress = `${full_address[0]}, ${full_address[1]}, ${full_address[2]}, ${longCountryName}, ${full_address[4]}`;
    setAddress(fullAddress);
    setAutocompleteFinished(true);

    if (full_address[0]) {
      setStreetAddress(full_address[0]);
    }

    if (full_address[1]) {
      setCity(full_address[1]);
    }

    if (full_address[2]) {
      setProvince(full_address[2]);
    }

    if (full_address[4]) {
      setPostalCode(full_address[4]);
    }
  };

  const handleEligibilityCheck = () => {
    let uid = new URLSearchParams(window.location.search).get("uid");
    if (uid) {
      // check that all spaces have been converted to pluses, due to URI encoding
      uid = uid.replace(/ /g, "+");
      setUID(uid);
      api.getPortInStatus(uid)
        .then(res => {
          if (res.data) {
            let getPortInStatusResponse = JSON.parse(res.data);
            if (getPortInStatusResponse.ExistsPendingPortIn) {
              setExistsPendingPortin(true);
            } else {

              // if no plans, redirect to home page
              if (getPortInStatusResponse.Plans) {
                setPlans(getPortInStatusResponse.Plans);
              } else {
                goTo("/");
              }

              // do rest of assignments
              setExistsPendingPortin(false);
              setFullName(getPortInStatusResponse.Name);
              setPhoneNumber(getPortInStatusResponse.PhoneNumber);
              setEmail(getPortInStatusResponse.Email);
              if (getPortInStatusResponse.CurrentSIMType && getPortInStatusResponse.CurrentSIMType === "ESIM") {
                setIsCurrentlyESIM(true); 
              }
            }
            setLoading(false);
          }
        })
        .catch(err => { 
          console.error(err);
          setLoading(false);
          setModalError("Sorry, something went wrong with checking your status.");
        });
    } 
    else {
      goTo("/");
    }
  }

  useEffect(() => {
    dispatch(saveRouteDetails("internal-transfer") as any);
    handleEligibilityCheck();
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
      setPageWidth(window.innerWidth);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  return (
    <PageLayout showPartnerNav>
      <title>Plan Change | PhoneBox</title>
      <Wrapper>
        <Container>
          <BackgroundDiv style={{ maxWidth: "900px", margin: "auto" }}>
            <BlueTitle className="p-2">
              Choose Your New Plan
            </BlueTitle>
            <BlueText>
              PhoneBox has a perfect deal to fit your needs.
            </BlueText>
            <BlueText style={{ marginTop: -6, marginBottom: 24 }}>
              You will need to switch your network during this process.
            </BlueText>
            {isShowPlans &&
              <div>
                <Row>
                  <Col>
                    <div className="p-2">
                      <Label bottomGap="16px">Full Name</Label>
                      <Form.Control value={fullName} disabled></Form.Control>
                    </div>
                    <div className="p-2">
                      <Label bottomGap="16px">Phone Number</Label>
                      <Form.Control type="text" value={phoneNumber} disabled></Form.Control>
                    </div>
                    <div className="p-2">
                      <Label bottomGap="16px">Email</Label>
                      <Form.Control type="text" value={email} disabled></Form.Control>
                    </div>
                  </Col>
                  <Col>
                    <div className="p-2">
                      <RequiredLabel>Select a Plan</RequiredLabel>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary">
                          {selectedPlan.PlanPrice === "" ? "Select a Plan" : `${selectedPlan.PlanCapacity} | $${selectedPlan.PlanPrice}`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {plans.map((p, index) => (
                            <Dropdown.Item key={index} onClick={() => onChangeSelectedPlan(p)}>
                              <Trans>{`${p.PlanCapacity} | $${p.PlanPrice}`}</Trans>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="p-2">
                      <RequiredLabel>SIM Card Type</RequiredLabel>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary">
                          {selectedSimCardType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {simCardTypes.map((s, index) => (
                            <Dropdown.Item key={index} onClick={() => onChangeSelectedSimType(s.value)}>
                              <Trans>{s.name}</Trans>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {esimSelected &&
                      <div className="p-2">
                        <RedText>Your device must be compatible with eSIM.</RedText>
                        <RedText>Please find these "Add eSIM" sections in your device, otherwise choose a Physical Sim Card.</RedText>
                        <RedText>{"iPhone: Settings > Cellular > Add eSIM"}</RedText>
                        <RedText>{"Android: Settings > Connection > SIM manager > Add eSIM"}</RedText>
                      </div>
                    }
                    {physicalSimSelected &&
                      <div className="p-2">
                        <RedText>No worries, we will start the process only once we've confirmed you've received your new SIM card.</RedText>
                      </div>
                    }
                  </Col>
                </Row>
                {physicalSimSelected &&
                <>
                    <div className="p-2">
                      <RequiredLabel>Address</RequiredLabel>
                      <CanadaPostAutoCompleteAddress selectedCountry={'CA'} onAutoCompleteAddress={getServiceAddressAutoComplete}/>
                    </div>
                    {autocompleteFinished &&
                      <Row className="mt-2">
                        <Col>
                          <div className="p-2">
                            <RequiredLabel bottomGap="16px">Unit No & Street Address</RequiredLabel>
                            <Form.Control value={streetAddress} onChange={(event) => setStreetAddress(event.target.value)}></Form.Control>
                          </div>
                          <div className="p-2">
                            <RequiredLabel bottomGap="16px">Province</RequiredLabel>
                            <Form.Control type="text" value={province} onChange={(event) => setProvince(event.target.value)}></Form.Control>
                          </div>
                        </Col>
                        <Col>
                          <div className="p-2">
                            <RequiredLabel bottomGap="16px">City</RequiredLabel>
                            <Form.Control value={city} onChange={(event) => setCity(event.target.value)}></Form.Control>
                          </div>
                          <div className="p-2">
                            <RequiredLabel bottomGap="16px">Postal Code</RequiredLabel>
                            <Form.Control type="text" value={postalCode} onChange={(event) => setPostalCode(event.target.value)}></Form.Control>
                          </div>
                        </Col>
                      </Row>
                    }
                  </>
                }
                <CenteredDiv>
                  <div className="p-3 d-flex flex-row align-items-center">
                    {acknowledgementChecked ? (
                      <FontAwesomeIcon style={{ color: '#333333' }} icon={faSquareCheck} fontSize={32} onClick={() => setAcknowledgementChecked(false)}></FontAwesomeIcon>
                    ) : (
                      <FontAwesomeIcon style={{ color: '#333333' }} icon={faSquare} fontSize={32} onClick={() => setAcknowledgementChecked(true)}></FontAwesomeIcon>
                    )}
                    <div className="p-2">
                      I acknowledge that the plan change will be effective from the next billing cycle, and that PhoneBox will change my network.
                    </div>
                  </div>
                </CenteredDiv>
                {error.length > 0 &&
                  <CenteredDiv style={{ color: "red", marginBottom: "12px" }}>
                    {error}
                  </CenteredDiv>
                }
                <CenteredDiv>
                  <CustomButton onClick={onClickRequestThePlan} disabled={loadingNext}>
                    {loadingNext && <Spinner variant="light" className="m-auto" animation="border" size="sm" />}
                    <span className="ms-2">
                      Request the Plan
                    </span>
                  </CustomButton>
                </CenteredDiv>
              </div>
            }
          </BackgroundDiv>
          
        </Container>
      </Wrapper>
      {showModal && 
        <Modal 
          onClose={onClickOK}
          maxWidth={"600px"}
          body={
            <div style={{ padding: 10, marginLeft: 20, marginRight: 20 }}>
              {loading &&
                <>
                  <div className="d-flex align-items-center">
                    <Spinner variant="primary" className="m-auto" animation="border" />
                  </div>
                  <br/>
                  <div className="text-center">
                      <small>Checking your eligibility...</small>
                  </div>
                </>
              }
              {!loading &&
                <>
                  {existsPendingPortin &&
                    <TextCenter>
                      <p>It seems like you already have a pending port in request.</p>
                      <p>Please contact our customer service team if you want to modify your request.</p>
                      <p>Click "OK" to be returned to the home page.</p>
                    </TextCenter>
                  }
                  {!existsPendingPortin && !isSuccess &&
                    <TextCenter>
                      <p>Congratulations! You have unlocked amazing PhoneBox deals!</p>                    
                      <p>Click "OK" to continue with the process.</p>                    
                    </TextCenter>
                  }
                  {isSuccess &&
                    <TextCenter>
                      <p>Thank you, we have received your request!</p>
                      <p>Please contact our customer service team if you want to modify your request.</p>
                      <p>Click "OK" to be returned to the home page.</p>
                    </TextCenter>
                  }
                  {modalError.length > 0 &&
                    <TextCenter>
                      <p style={{ color: "red" }}>{modalError}</p>
                    </TextCenter>
                  }
                  <CenteredDiv style={{ marginTop: "12px" }}>
                    <Button variant="primary" onClick={onClickOK}>
                      OK
                    </Button>
                  </CenteredDiv>
                </>
              }
            </div>
          }
        />
      }
    </PageLayout>
  )
}

const BlueTitle = styled.h3`
  font-weight: 600;
  color: #0494CA;
  font-size: 30px;
`

const BlueText = styled.div`
  color: #0494CA;
  margin: 12px 0px 12px 6px;
`

const Wrapper = styled.div`
  padding-top: 40px;
`;

const TextCenter = styled.div`
  text-align: center;
`

const CenteredDiv = styled.div`
  width: fit-content;
  margin: auto;
`

const BackgroundDiv = styled.div`
  background-color: #f8f8f8;
  padding: 24px;
  border-radius: 10px;
`

const Label = styled.div<{ topGap?: string; bottomGap?: string }>`
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-top: ${(props) => props.topGap};
  margin-bottom: ${(props) => props.bottomGap};
  width: max-content;
  span {
    color: red;
  }

  @media (max-width: 456px) {
    font-size: 17px;
  }
`;

const CustomButton = styled.button`
  font-size: 18px;
  font-weight: 400;
  transition: 0.7s;
  color: #fff;
  margin: 0px;
  outline: none !important;
  padding: 8px 40px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: none;
  margin: 15px 0px;
  :hover {
    color: #fff;
  }
`;

const RequiredLabel = styled.p<{ bottomGap?: string }>`
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-bottom: ${(props) => props.bottomGap};
  :after {
    color: #e32;
    content: " *";
    display: inline;
  }

  @media (max-width: 418px) {
    font-size: 14px;
  }
`;

const RedText = styled.p`
  color: #e32;
`


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
